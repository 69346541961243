// add negative offset - otherwise outline will be clipped
[id^="pendo-close-"] {
  outline-offset: -$outline-width;
}

// center modal
[id^="pendo-g-"] {
  margin: 0 !important;
  transform: translate(-50%, -50%);
}

// display loader until modal is shown
[id^="pendo-g-"][style*="visibility: hidden;"] [aria-modal="true"] {
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    visibility: visible;
    z-index: 998;
    max-height: 60vh;
    margin: auto;
  }

  &::after {
    content: url('data:image/svg+xml;base64,PHN2ZyBjbGFzcz0ic3Bpbm5lciIgc3R5bGU9ImhlaWdodDogNDhweDsgd2lkdGg6IDQ4cHg7IiB2aWV3Qm94PSIwIDAgNjYgNjYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHN0eWxlPgogICAgLnNwaW5uZXIgewogICAgICBtYXJnaW46IGF1dG87CiAgICAgIGFuaW1hdGlvbjogcm90YXRvciAxLjJzIGxpbmVhciBpbmZpbml0ZTsKICAgIH0KCiAgICBAa2V5ZnJhbWVzIHJvdGF0b3IgewogICAgICAwJSB7IHRyYW5zZm9ybTogcm90YXRlKDBkZWcpOyB9CiAgICAgIDEwMCUgeyB0cmFuc2Zvcm06IHJvdGF0ZSgyNzBkZWcpOyB9CiAgICB9CgogICAgLnBhdGggewogICAgICBzdHJva2UtZGFzaGFycmF5OiAxODc7CiAgICAgIHN0cm9rZS1kYXNob2Zmc2V0OiAwOwogICAgICB0cmFuc2Zvcm0tb3JpZ2luOiBjZW50ZXI7CiAgICAgIGFuaW1hdGlvbjoKICAgICAgICBkYXNoIDEuMnMgZWFzZS1pbi1vdXQgaW5maW5pdGUsCiAgICAgICAgY29sb3JzIDQuOHMgZWFzZS1pbi1vdXQgaW5maW5pdGU7CiAgICB9CgogICAgQGtleWZyYW1lcyBkYXNoIHsKICAgICAgMCUgeyBzdHJva2UtZGFzaG9mZnNldDogMTg3OyB9CiAgICAgIDUwJSB7CiAgICAgICAgc3Ryb2tlLWRhc2hvZmZzZXQ6IDQ0OwogICAgICAgIHRyYW5zZm9ybTogcm90YXRlKDEzNWRlZyk7CiAgICAgIH0KICAgICAgMTAwJSB7CiAgICAgICAgc3Ryb2tlLWRhc2hvZmZzZXQ6IDE4NzsKICAgICAgICB0cmFuc2Zvcm06IHJvdGF0ZSg0NTBkZWcpOwogICAgICB9CiAgICB9CiAgPC9zdHlsZT4KICA8Y2lyY2xlIGNsYXNzPSJwYXRoIiBmaWxsPSJub25lIiBzdHJva2Utd2lkdGg9IjYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlPSJjdXJyZW50Q29sb3IiIGN4PSIzMyIgY3k9IjMzIiByPSIzMCI+PC9jaXJjbGU+Cjwvc3ZnPgo=');
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    visibility: visible;
    z-index: 999;
  }
}
