@import './fonts';
@import './mixins';
@import './placeholders';
@import './variables';
@import './components';
@import './material-reset';
@import './functions';

@import 'amway-icons';
@import 'amway-icons-regular.scss';

@import './amw-icons';
@import './amw-icons-default-styles';
@import './mat-dialog-default-styles';
@import './mat-radio-default-styles';
@import './pendo-modal';

* {
  box-sizing: border-box;
}

::-ms-clear {
  display: none;
}

html {
  height: 100%;
}

.receivable {
  font-family: $font__primary;
  a {
    text-decoration: underline;
  }
}

html.cdk-global-scrollblock {
  height: vh(100);
  position: static;
  overflow: hidden;

  &:not(.none-floating-body) {
    body {
      position: fixed;
      width: 100%;
      top: inherit;
      left: inherit;
    }
  }
}

body {
  min-height: 100%;
  min-width: $base__min-width;
  display: flex;
  font: $font__size $font__primary;
  color: $color__mine_shaft;
  overflow-anchor: none; // Description of this css property on https://developer.mozilla.org/en-US/docs/Web/CSS/overflow-anchor

  &.full-screen-loader {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: inherit;
    font: inherit;

    &:hover {
      text-decoration: inherit;
      color: inherit;
    }

    &:focus {
      color: $color__mine_shaft;
      @extend %focus-outline;
    }

    &[href^="tel:"]:hover,
    &[href^="mailto:"]:hover {
      color: $color__mine_shaft_70;
      text-decoration: underline;
    }
  }
}

div[role='button'] {
  &:focus {
    border: none !important;
    @extend %focus-outline;
  }
}

button {
  background: transparent;
  border: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;

  &:focus {
    @extend %focus-outline;
  }
}

.no-y-scroll {
  overflow-y: hidden;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

// recently !important was added to outline styles within shared components
// to be able to override such styles, it has to be used the complete selector
$data_attributes: '[data-focus-type=mouse]', '[data-focus-type=touch]', '[data-focus-type=program]:not([data-previous-element-focus-type=keyboard])';
$selectors_forbidden_outline:
  '*{placeholder}',
  '.amer-header .amer-link .link{placeholder}:focus .link__text',
  '.amw-footer .link{placeholder}:focus .link__text',
  '.amw-footer .amer-link--reverse .link{placeholder}:focus',
  '.account-bar-content.amw-dropdown__toggle--button{placeholder}:focus',
  '.amer-header .close-button{placeholder}:focus',
  '.amer-header .hamburger-toggle{placeholder}:focus',
  '.amer-header .sidebar__heading--button{placeholder}:focus',
  '.amer-minicart .amer-minicart-control{placeholder}:focus',
  '.amer-minicart .link{placeholder}:focus',
  '.amw-header-search__submit{placeholder}:focus',
  '.account-bar-content.amw-dropdown__toggle--button{placeholder}:focus',
  '.amer-header .amw-dropdown__menu__item button{placeholder}:focus',
  '.amer-header .amw-dropdown__toggle--button{placeholder}:focus',
  '.amer-header .amw-dropdown__toggle--mobile{placeholder}:focus',
  '.amw-dropdown__menu__item button{placeholder}:focus',
  '.amw-dropdown__toggle--button{placeholder}:focus',
  '.footer-bottom .link{placeholder}:focus .amer-image',
  '.amw-footer-social-media .link{placeholder}:focus',
  '.amer-header-logo .link{placeholder}:focus .amer-image';
$selectors: generate_forbidden_outline_selectors($data_attributes, $selectors_forbidden_outline);

#{$selectors} {
  outline: none !important;
}

fieldset {
  min-inline-size: auto;
}

// Originally this selector is used to hide nested elements in accordion, when it's closed, but it can be used in other
// places as well if needed.
// To exclude nested elements from keyboard navigation, when accordion is closed, - `visibility: hidden` is used.
// In case nested elements of an accordion have `visibility: visible` somewhere
// (e.g. inline-style, css, etc) - such elements could be focused via keyboard, even though
// accordion is closed, so to prevent it - we'll just set `visibility: hidden` to all nested elements
// and then, once accordion is opened, values will be reset to the original ones.
// NOTE: such approach can't beat visibility with `!important` rule, if potential selector has higher priority than this one.
.hide-all-elements * {
  visibility: hidden !important;
}
