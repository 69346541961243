@import 'scss/mixins';
@import 'scss/variables';

.ensure-fullscreen {
  position: relative;
  z-index: $z-index-1000;
}

$padding-right-left: 20px;

.mat-tooltip-panel {
  @include tooltip_side_options($color__mine_shaft);
  margin: 0 !important;
  overflow: visible !important;

  .mat-tooltip {
    border-radius: 6px;
    max-width: 200px;
    padding: 16px;
    margin: 0;
    background: $color__mine_shaft;
    font: 14px $font__primary;
    text-align: left;
    overflow: visible;

    &.amw-tooltip-clipboard {
      color: $color__ghost-white;
      padding: 10px $padding-right-left;
      font: 14px $font__primary--medium;
    }

    a {
      text-decoration: underline;

      &:focus {
        color: $color__white;
      }
    }

    &:after {
      content: "";
      position: absolute;
      border-style: solid;
      margin-left: -5px;
      border-width: 5px;
    }
  }
}
