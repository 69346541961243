@import 'amway-icons';
@import 'amway-icons-solid';

@font-face {
  font-family: 'AmwayIconsBc';
  src:
    url('../fonts/amway-icons/AmwayIcons.ttf?g4uil') format('truetype'),
    url('../fonts/amway-icons/AmwayIcons.woff?g4uil') format('woff'),
    url('../fonts/amway-icons/AmwayIcons.svg?g4uil#AmwayIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="amw-icon-"], [class*=" amw-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AmwayIconsBc' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.amw-icon-contract:before {
  content: "\e92b";
  color: #fff;
}

.amw-icon-first-year:before {
  content: "\e92c";
  color: #fff;
}

.amw-icon-file-invoice:before {
  content: "\e92d";
}

.amw-icon-bi-direct-arrow:before {
  content: "\e92e";
}

.amw-icon-house:before {
  content: "\e92f";
}

.amw-icon-cc-union-pay:before {
  content: "\e930";
}

.amw-icon-cc-amex:before {
  content: "\e931";
}

.amw-icon-cc-diners-club:before {
  content: "\e932";
}

.amw-icon-cc-discover:before {
  content: "\e933";
}

.amw-icon-cc-jcb:before {
  content: "\e934";
}

.amw-icon-cc-mastercard:before {
  content: "\e935";
}

.amw-icon-cc-visa:before {
  content: "\e936";
}

.amw-icon-dollar-sign-light:before {
  content: "\e937";
}

.amw-icon-business-time:before {
  content: "\e938";
}

.amw-icon-heart-circle:before {
  content: "\e939";
}

.amw-icon-shield-alt:before {
  content: "\e93a";
}

.amw-icon-file-contract:before {
  content: "\e93b";
}

.amw-icon-store:before {
  content: "\e93c";
}

.amw-icon-history:before {
  content: "\e93d";
}

.amw-icon-credit-card:before {
  content: "\e93e";
}

.amw-icon-user-light:before {
  content: "\e93f";
}

.amw-icon-bell:before {
  content: "\e940";
}

.amw-icon-user-clock:before {
  content: "\e941";
}

.amw-icon-check-thin:before {
  content: "\e942";
}

.amw-icon-hand-holding-heart:before {
  content: "\e943";
}

.amw-icon-lightbulb-on:before {
  content: "\e944";
}

.amw-icon-trash:before {
  content: "\e945";
}

.amw-icon-box-heart:before {
  content: "\e946";
}

.amw-icon-exchange:before {
  content: "\e947";
}

.amw-icon-user-clock_duotone .path1:before {
  content: "\e948";
  color: rgb(44, 44, 44);
}

.amw-icon-user-clock_duotone .path2:before {
  content: "\e949";
  margin-left: -1.25em;
  color: rgb(56, 83, 154);
}

.amw-icon-check-circle_duotone .path1:before {
  content: "\e94a";
  color: rgb(44, 44, 44);
}

.amw-icon-check-circle_duotone .path2:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(17, 135, 76);
}

.amw-icon-AmPerks_Heart-2:before {
  content: "\e98d";
  color: #37529a;
}

.amw-icon-dash-line:before {
  content: "\e9b1";
}

.amw-icon-circle-segments:before {
  content: "\e9b3";
}

.amw-icon-downleg:before {
  content: "\e9bf";
}

.amw-icon-tile_view:before {
  content: "\e9d0";
}

.amw-icon-clipboard-check:before {
  content: "\e9d1";
}

.amw-icon-chart-pie:before {
  content: "\e9d2";
}

.amw-icon-cut:before {
  content: "\e9d3";
}

.amw-icon-ellipsis-h:before {
  content: "\e9d4";
}

.amw-icon-angle-double-up:before {
  content: "\e9d5";
}

.amw-icon-angle-double-down:before {
  content: "\e9d6";
}

.amw-icon-warning:before {
  content: "\e9d7";
}

.amw-icon-phone:before {
  content: "\e9d8";
}

.amw-icon-rocket-launch:before {
  content: "\e9d9";
}

.amw-icon-comment-lines:before {
  content: "\e9da";
}

.amw-icon-caret-up:before {
  content: "\e9db";
}

.amw-icon-caret-down:before {
  content: "\e9dc";
}

.amw-icon-file-pdf:before {
  content: "\e9dd";
}

.amw-icon-play-circle:before {
  content: "\e9de";
}

.amw-icon-check-square:before {
  content: "\e9df";
}

.amw-icon-minus-circle:before {
  content: "\e9e0";
}

.amw-icon-link:before {
  content: "\e9e1";
}

.amw-icon-th-list:before {
  content: "\e9e2";
}

.amw-icon-search-minus:before {
  content: "\e9e3";
}

.amw-icon-search-plus:before {
  content: "\e9e4";
}

.amw-icon-users:before {
  content: "\e9e5";
}

.amw-icon-shield:before {
  content: "\e9e6";
}

.amw-icon-share:before {
  content: "\e9e7";
}

.amw-icon-search:before {
  content: "\e9e8";
}

.amw-icon-expand-alt:before {
  content: "\e9e9";
}

.amw-icon-undo:before {
  content: "\e9ea";
}

.amw-icon-user-solid:before {
  content: "\e9eb";
}

.amw-icon-registered:before {
  content: "\e9ec";
}

.amw-icon-shopping-cart:before {
  content: "\e9ed";
}

.amw-icon-print:before {
  content: "\e9ee";
}

.amw-icon-plus:before {
  content: "\e9ef";
}

.amw-icon-comment:before {
  content: "\e9f0";
}

.amw-icon-percent:before {
  content: "\e9f1";
}

.amw-icon-pencil:before {
  content: "\e9f2";
}

.amw-icon-newspaper:before {
  content: "\e9f3";
}

.amw-icon-edit:before {
  content: "\e9f4";
}

.amw-icon-minus:before {
  content: "\e9f5";
}

.amw-icon-mail:before {
  content: "\e9f6";
}

.amw-icon-sign-out:before {
  content: "\e9f7";
}

.amw-icon-lock:before {
  content: "\e9f8";
}

.amw-icon-book-open:before {
  content: "\e9f9";
}

.amw-icon-leaf:before {
  content: "\e9fa";
}

.amw-icon-info-circle:before {
  content: "\e9fb";
}

.amw-icon-list:before {
  content: "\e9fc";
}

.amw-icon-home2:before {
  content: "\e9fd";
}

.amw-icon-question-circle-solid:before {
  content: "\e9fe";
}

.amw-icon-question-circle:before {
  content: "\e9ff";
}

.amw-icon-angle-double-left:before {
  content: "\ea00";
}

.amw-icon-exclamation:before {
  content: "\ea01";
}

.amw-icon-cog:before {
  content: "\ea02";
}

.amw-icon-hdd:before {
  content: "\ea03";
}

.amw-icon-file-alt:before {
  content: "\ea04";
}

.amw-icon-hourglass:before {
  content: "\ea05";
}

.amw-icon-sync:before {
  content: "\ea06";
}

.amw-icon-user:before {
  content: "\ea07";
}

.amw-icon-dollar-sign:before {
  content: "\ea08";
}

.amw-icon-file-signature:before {
  content: "\ea09";
}

.amw-icon-chart-bar:before {
  content: "\ea0a";
}

.amw-icon-angle-double-right:before {
  content: "\ea0b";
}

.amw-icon-cross:before {
  content: "\ea0c";
}

.amw-icon-times-circle:before {
  content: "\ea0d";
}

.amw-icon-angle-left:before {
  content: "\ea0e";
}

.amw-icon-angle-right:before {
  content: "\ea0f";
}

.amw-icon-check:before {
  content: "\ea10";
}

.amw-icon-check-circle:before {
  content: "\ea11";
}

.amw-icon-calendar:before {
  content: "\ea12";
}

.amw-icon-calculator:before {
  content: "\ea13";
}

.amw-icon-browser:before {
  content: "\ea14";
}

.amw-icon-arrow-with-circle-up:before {
  content: "\ea15";
}

.amw-icon-angle-down:before {
  content: "\ea16";
}

.amw-icon-angle-up:before {
  content: "\ea17";
}

.amw-icon-archive:before {
  content: "\ea18";
}

.amw-icon-heart:before {
  content: "\ea19";
}

.amw-icon-exclamation-circle:before {
  content: "\ea1a";
}

.amw-icon-plus-circle:before {
  content: "\ea1b";
}

.amw-icon-bars-plus:before {
  content: "\ea1c";
}

.amw-icon-block:before {
  content: "\ea1d";
}

.amw-icon-amway-shield:before {
  content: "\ea1e";
}

.amw-icon-menu:before {
  content: "\ea1f";
}

.amw-icon-ditto:before {
  content: "\ea20";
}

.amw-icon-new-site .path1:before {
  content: "\ea21";
  color: rgb(1, 1, 1);
}

.amw-icon-new-site .path2:before {
  content: "\ea22";
  margin-left: -0.7724609375em;
  color: rgb(3, 4, 4);
}

.amw-icon-rearrange:before {
  content: "\ea23";
}

.amw-icon-search-results:before {
  content: "\ea24";
}

.amw-icon-youtube:before {
  content: "\e94c";
}

.amw-icon-linkedin:before {
  content: "\e94d";
}

.amw-icon-twitter:before {
  content: "\e94e";
}

.amw-icon-instagram:before {
  content: "\e94f";
}

.amw-icon-facebook-f:before {
  content: "\e950";
}

.amw-icon-facebook:before {
  content: "\e951";
}

.amw-icon-external-link-square:before {
  content: "\e952";
}

.amw-icon-exclamation-square:before {
  content: "\e953";
}

.amw-icon-warning-light:before {
  content: "\e954";
}

.amw-icon-lightning:before {
  content: "\e955";
  color: #38539a;
}

.amw-icon-down-arrow:before {
  content: "\e956";
}

.amw-icon-first-year1:before {
  content: "\e92a";
  color: #fff;
}

.amw-icon-file-invoice1:before {
  content: "\e900";
}

.amw-icon-bi-direct-arrow1:before {
  content: "\e901";
}

.amw-icon-house1:before {
  content: "\e925";
}

.amw-icon-cc-union-pay1:before {
  content: "\e91c";
}

.amw-icon-cc-amex1:before {
  content: "\e91d";
}

.amw-icon-cc-diners-club1:before {
  content: "\e91e";
}

.amw-icon-cc-discover1:before {
  content: "\e91f";
}

.amw-icon-cc-jcb1:before {
  content: "\e920";
}

.amw-icon-cc-mastercard1:before {
  content: "\e921";
}

.amw-icon-cc-visa1:before {
  content: "\e922";
}

.amw-icon-dollar-sign-light1:before {
  content: "\e91b";
}

.amw-icon-business-time1:before {
  content: "\e902";
}

.amw-icon-heart-circle1:before {
  content: "\e903";
}

.amw-icon-shield-alt1:before {
  content: "\e904";
}

.amw-icon-file-contract1:before {
  content: "\e905";
}

.amw-icon-store1:before {
  content: "\e906";
}

.amw-icon-history1:before {
  content: "\e907";
}

.amw-icon-credit-card1:before {
  content: "\e908";
}

.amw-icon-user-light1:before {
  content: "\e909";
}

.amw-icon-bell1:before {
  content: "\e90a";
}

.amw-icon-user-clock1:before {
  content: "\e90b";
}

.amw-icon-check-thin1:before {
  content: "\e90c";
}

.amw-icon-hand-holding-heart1:before {
  content: "\e90d";
}

.amw-icon-lightbulb-on1:before {
  content: "\e90e";
}

.amw-icon-trash1:before {
  content: "\e90f";
}

.amw-icon-box-heart1:before {
  content: "\e910";
}

.amw-icon-exchange1:before {
  content: "\e911";
}

.amw-icon-user-clock_duotone1 .path1:before {
  content: "\e912";
  color: rgb(44, 44, 44);
}

.amw-icon-user-clock_duotone1 .path2:before {
  content: "\e913";
  margin-left: -1.25em;
  color: rgb(56, 83, 154);
}

.amw-icon-check-circle_duotone1 .path1:before {
  content: "\e914";
  color: rgb(44, 44, 44);
}

.amw-icon-check-circle_duotone1 .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(17, 135, 76);
}

.amw-icon-AmPerks_Heart-21:before {
  content: "\e973";
  color: #37529a;
}

.amw-icon-dash-line1:before {
  content: "\e974";
}

.amw-icon-circle-segments1:before {
  content: "\e975";
}

.amw-icon-downleg1:before {
  content: "\e976";
}

.amw-icon-tile_view1:before {
  content: "\e977";
}

.amw-icon-clipboard-check1:before {
  content: "\e978";
}

.amw-icon-chart-pie1:before {
  content: "\e979";
}

.amw-icon-cut1:before {
  content: "\e97a";
}

.amw-icon-ellipsis-h1:before {
  content: "\e97b";
}

.amw-icon-angle-double-up1:before {
  content: "\e97c";
}

.amw-icon-angle-double-down1:before {
  content: "\e97d";
}

.amw-icon-warning1:before {
  content: "\e97e";
}

.amw-icon-phone1:before {
  content: "\e97f";
}

.amw-icon-rocket-launch1:before {
  content: "\e980";
}

.amw-icon-comment-lines1:before {
  content: "\e981";
}

.amw-icon-caret-up1:before {
  content: "\e982";
}

.amw-icon-caret-down1:before {
  content: "\e983";
}

.amw-icon-file-pdf1:before {
  content: "\e984";
}

.amw-icon-play-circle1:before {
  content: "\e985";
}

.amw-icon-check-square1:before {
  content: "\e986";
}

.amw-icon-minus-circle1:before {
  content: "\e987";
}

.amw-icon-link1:before {
  content: "\e988";
}

.amw-icon-th-list1:before {
  content: "\e989";
}

.amw-icon-search-minus1:before {
  content: "\e98a";
}

.amw-icon-search-plus1:before {
  content: "\e98b";
}

.amw-icon-users1:before {
  content: "\e98c";
}

.amw-icon-shield1:before {
  content: "\e98e";
}

.amw-icon-share1:before {
  content: "\e98f";
}

.amw-icon-search1:before {
  content: "\e990";
}

.amw-icon-expand-alt1:before {
  content: "\e991";
}

.amw-icon-undo1:before {
  content: "\e992";
}

.amw-icon-user-solid1:before {
  content: "\e993";
}

.amw-icon-registered1:before {
  content: "\e994";
}

.amw-icon-shopping-cart1:before {
  content: "\e995";
}

.amw-icon-print1:before {
  content: "\e996";
}

.amw-icon-plus1:before {
  content: "\e997";
}

.amw-icon-comment1:before {
  content: "\e998";
}

.amw-icon-percent1:before {
  content: "\e999";
}

.amw-icon-pencil1:before {
  content: "\e99a";
}

.amw-icon-newspaper1:before {
  content: "\e99b";
}

.amw-icon-edit1:before {
  content: "\e99c";
}

.amw-icon-minus1:before {
  content: "\e99d";
}

.amw-icon-mail1:before {
  content: "\e99e";
}

.amw-icon-sign-out1:before {
  content: "\e99f";
}

.amw-icon-lock1:before {
  content: "\e9a0";
}

.amw-icon-book-open1:before {
  content: "\e9a1";
}

.amw-icon-leaf1:before {
  content: "\e9a2";
}

.amw-icon-info-circle1:before {
  content: "\e9a3";
}

.amw-icon-list1:before {
  content: "\e9a4";
}

.amw-icon-home21:before {
  content: "\e9a5";
}

.amw-icon-question-circle-solid1:before {
  content: "\e9a6";
}

.amw-icon-question-circle1:before {
  content: "\e9a7";
}

.amw-icon-angle-double-left1:before {
  content: "\e9a8";
}

.amw-icon-exclamation1:before {
  content: "\e9a9";
}

.amw-icon-cog1:before {
  content: "\e9aa";
}

.amw-icon-hdd1:before {
  content: "\e9ab";
}

.amw-icon-file-alt1:before {
  content: "\e9ac";
}

.amw-icon-hourglass1:before {
  content: "\e9ad";
}

.amw-icon-sync1:before {
  content: "\e9ae";
}

.amw-icon-user1:before {
  content: "\e9af";
}

.amw-icon-dollar-sign1:before {
  content: "\e9b0";
}

.amw-icon-file-signature1:before {
  content: "\e9b2";
}

.amw-icon-chart-bar1:before {
  content: "\e9b4";
}

.amw-icon-angle-double-right1:before {
  content: "\e9b5";
}

.amw-icon-cross1:before {
  content: "\e9b6";
}

.amw-icon-times-circle1:before {
  content: "\e9b7";
}

.amw-icon-angle-left1:before {
  content: "\e9b8";
}

.amw-icon-angle-right1:before {
  content: "\e9b9";
}

.amw-icon-check1:before {
  content: "\e9ba";
}

.amw-icon-check-circle1:before {
  content: "\e9bb";
}

.amw-icon-calendar1:before {
  content: "\e9bc";
}

.amw-icon-calculator1:before {
  content: "\e9bd";
}

.amw-icon-browser1:before {
  content: "\e9be";
}

.amw-icon-arrow-with-circle-up1:before {
  content: "\e9c0";
}

.amw-icon-angle-down1:before {
  content: "\e9c1";
}

.amw-icon-angle-up1:before {
  content: "\e9c2";
}

.amw-icon-archive1:before {
  content: "\e9c3";
}

.amw-icon-heart1:before {
  content: "\e9c4";
}

.amw-icon-exclamation-circle1:before {
  content: "\e9c5";
}

.amw-icon-plus-circle1:before {
  content: "\e9c6";
}

.amw-icon-bars-plus1:before {
  content: "\e9c7";
}

.amw-icon-block1:before {
  content: "\e9c8";
}

.amw-icon-amway-shield1:before {
  content: "\e9c9";
}

.amw-icon-menu1:before {
  content: "\e9ca";
}

.amw-icon-ditto1:before {
  content: "\e9cb";
}

.amw-icon-new-site1 .path1:before {
  content: "\e9cc";
  color: rgb(1, 1, 1);
}

.amw-icon-new-site1 .path2:before {
  content: "\e9cd";
  margin-left: -0.7724609375em;
  color: rgb(3, 4, 4);
}

.amw-icon-rearrange1:before {
  content: "\e9ce";
}

.amw-icon-search-results1:before {
  content: "\e9cf";
}

.amw-icon-youtube1:before {
  content: "\e916";
}

.amw-icon-linkedin1:before {
  content: "\e917";
}

.amw-icon-twitter1:before {
  content: "\e918";
}

.amw-icon-instagram1:before {
  content: "\e919";
}

.amw-icon-facebook-f1:before {
  content: "\e91a";
}

.amw-icon-facebook1:before {
  content: "\e923";
}

.amw-icon-external-link-square1:before {
  content: "\e924";
}

.amw-icon-exclamation-square1:before {
  content: "\e926";
}

.amw-icon-warning-light1:before {
  content: "\e928";
}

.amw-icon-lightning1:before {
  content: "\e927";
  color: #38539a;
}

.amw-icon-down-arrow1:before {
  content: "\e929";
}
