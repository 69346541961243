@import './functions';

// typography
$font__size: 16px;
$font__size--big: 18px;
$font__size--big--icon: 19px;
$font__size--huge: 28px;
$font__size--large: 24px;
$font__size--large--alt: 21px;
$font__size--medium: 14px;
$font__size--small: 12px;
$font__fallback: sans-serif;

$line__height--huge: 28px;
$line__height--large: 24px;
$line__height--big: 18px;

$font__header-size: 42px;
$font__header-size--medium: 32px;
$font__header-size--small: 28px;

$font__header: 'GT Walsheim Pro', $font__fallback;
$font__primary: 'GTWalsheimProRegular', $font__fallback;
$font__primary--medium: 'GTWalsheimProMedium', $font__fallback;
$font__primary--bold: 'GTWalsheimProBold', $font__fallback;

// font weight
$thin: 100;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 900;

// breakpoints
$breakpoint__sm: 320px;
$breakpoint__sm_l: 480px;
$breakpoint__md_s: 620px;
$breakpoint__md: 768px;
$breakpoint__md_m: 992px;
$breakpoint__md_l: 1024px;
$breakpoint__lg_s: 1080px;
$breakpoint__lg: 1280px;
$breakpoint__lg_l: 1440px;

// dimensions
$base__min-width: $breakpoint__sm;
$base__max-width: $breakpoint__lg;
$gap__sm: 8px;
$gap__md: 16px;
$gap__lg: 24px;
$base__padding: $gap__md 40px;
$base-margin: 0 auto;

// "my account" cards
$basic-card-height: 170px;
$basic-card-width: 376px;

// colors
$color__black: #000000;
$color__black-smoke: rgba($color__black, .19);
$color__black-blond: rgba($color__black, 0.2);
$color__grey84: rgba($color__black, 0.29);
$color__black-light: rgba($color__black, .04);
$color__mine_shaft: #2c2c2c;
$color__mine_shaft_70: rgba($color__mine_shaft, 0.7);
$color__mine_shaft_50: rgba($color__mine_shaft, 0.5);
$color__mine_shaft_25: rgba($color__mine_shaft, 0.25);
$color__dark_grey: #303030;
$color__light_grey: #cccccc;
$color__charcoal: #424242;
$color__davy_grey: #555;
$color__mid_grey: #666;
$color__20_mid_grey: #707070;
$color__grey: #757575;
$color__mid_grey3: #919191;
$color__nobel: #949494;
$color__nobel_50: rgba($color__nobel, 0.5);
$color__grey72: #b2b2b2;
$color__grey5: #b5b5b5;
$color__silver: #bababa;
$color__silver2: #c4c4c4;
$color__silver2_50: rgba($color__silver2, 0.5);
$color__gainsboro: #dadada;
$color__platinum: #e4e4e4;
$color__disabled_bg: #eeeeee;
$color__seashell: #f1f1f1;
$color__white-smoke: #f4f4f4;
$color__snow: #f9f9f9;
$color__alabaster: #fafafa;
$color__linen: #fdf4f4;
$color__white: #ffffff;
$color__white_70: rgba($color__white, 0.7);
$color__white_50: rgba($color__white, 0.5);
$color__prussian-blue: #002f5f;
$color__mariner-blue: #37529a;
$color__purple: #38539a;
$color__light-purple: #4b6fcc;
$color__dark-blue: #396e75;
$color__cerulean: #007eb0;
$color__pigeon-blue-light: #7287bb;
$color__polo-blue: #94a3cd;
$color__pale-purple: #abbff4;
$color__near_salem: #268840;
$color__forest_green: #107f47;
$color__fern_frond: #546223;
$color__fern_frond_70: rgba($color__fern_frond, 0.7);
$color__fern_frond_50: rgba($color__fern_frond, 0.5);
$color__padua: #7fbc96;
$color__saltpan: #f0fae5;
$color__red: #e71d3b;
$color__amaranth: #e21d4a;
$color__amaranth_light: rgba($color__amaranth, 0.4);
$color__near-electric: #db0b00;
$color__near-electric-light: rgba($color__near-electric, 0.4);
$color__crimson: #ed174a;
$color__crimson-dark: #d91734;
$color__carissma: #eb8593;
$color__burgundy: #e0b4b4;
$color__burgundy-light: #f8e0e0;
$color__blue: #a9d5de;
$color__blue-light: #b5e7fa;
$color__green: #a3c293;
$color__green-light: #cffbdb;
$color__yellow: #c9ba9b;
$color__yellow-light: #faeca6;
$color__floral-white: #fff9f0;
$color__ghost-white: #f1f2ff;
$color__siena: #7f3e3e;
$color__chelsea-gem: #a26a00;
$color__rich_gold: #a65523;
$color__seashell_renewal: #fff1e8;
$color__tutu: #fff2e2;
$color__putty: #d1b278;
$color__golden-brown: #a66400;
$color__light-red: #FFE2E2;
$color__old_lace: #f8f8e7;
$color__granite_gray: #62625a;

// common elements
$header__height: 64px;
$header-navigation__height: 81px;

$page-separator__border: thin solid $color__platinum;

// modals
$modal-height-default: 100%;
$modal-max-height-default: vh(90);

$modal-width-sm: (
  sm_l_down: calc(100vw - 32px),
  md_down: 50vw,
  md_l_down: 50vw,
  lg_down: 33.33vw,
  lg_up: 33.33vw,
  max: 520px,
);

$modal-width-md: (
  sm_l_down: calc(100vw - 32px),
  md_down: 50vw,
  md_l_down: 50vw,
  lg_down: 50vw,
  lg_up: 33.33vw,
  max: 520px,
);

$modal-width-lg: (
  sm_l_down: calc(100vw - 32px),
  md_down: 50vw,
  md_l_down: 50vw,
  lg_down: 50vw,
  lg_up: 50vw,
  max: 790px,
);

// video-player
$video-player-default-width: 90%;
$video-player-default-height: 50%;
$video-player-mobile-default-width: 90%;
$video-player-mobile-default-height: 50%;
$video-player-top-position-mobile: 40%;

$outline-width: 4px;

// amw-wave-header
$amw-wave-header-height: 192px;
$amw-wave-header-height__lg: 162px;
$amw-wave-header-height__md_l: 142px;
$amw-wave-header-height__sm: 122px;

// fields
$text-field-padding-horizontal: 12px;
$text-field-padding-vertical: 11px;
$field-fixed-width: 344px;
$select-width__sm: 76px;


$default_font_size--welcome-name: 49px;
$default_font_size--ibo-num: 21px;

//z-indexes
$z-index-negative: -1;
$z-index-default: 1;
$z-index-1000: 1000;
$z-index-2000: 2000;
$z-index-toastr: 110000;
$z-index-modal: 100000;
$z-index-tooltip: 1000000;

$full-width-layout-left-right-over-lg-indent: 50%;

// table
$main-cell-width: 50%;

// percents
$fifty-percent: 50%;
$thirty-three-and-three-percent: 33.3%;
$one-hundred-percent: 100%;
