@import './variables';
@import './placeholders';

// Accordion
.mat-accordion {
  .mat-expansion-panel {
    box-shadow: none;
    margin: 0;

    &:first-of-type {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .mat-expansion-panel-header-title {
    color: inherit;
    align-items: center;
  }

  .mat-expansion-indicator::after {
    color: inherit;
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}

// Checkbox for 'Join for free today' section on about AmPerks page
.checkbox-about-am-perks-agreement {
  .amw-checkbox {
    .mat-checkbox-inner-container {
      @include dimension(14px);
      margin-top: 0;
      margin-right: 15px;

      @include sm_l {
        margin-top: 2px;
        margin-right: 10px;
      }
    }

    .mat-checkbox-frame {
      border-radius: 3px;
      border: 2px solid $color__purple;
      background: transparent;
    }

    &.mat-checkbox-checked.mat-accent .mat-checkbox-background {
      border-radius: 3px;
      background-color: $color__purple;
    }
  }

  .mat-checkbox-label {
    font: 12px $font__primary;
    line-height: 14px;
    color: $color__purple;
    text-align: left;

    @include sm_l {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

amw-opt-in-out {
  .mat-checkbox-inner-container {
    margin-top: 3px;
  }
}

// Dropdown outlines
.cdk-overlay-pane {
  &.opened {
    .mat-autocomplete-visible {
      min-width: auto;
    }
  }
}
