@use "sass:list";

@import './mixins';
@import './variables';

%vertical-align-baseline {
  display: flex;
  align-items: baseline;
}

%vertical-align-center {
  display: flex;
  align-items: center;
}

%vertical-align-stretch {
  display: flex;
  align-items: stretch;
}

%vertical-align-start {
  display: flex;
  align-items: flex-start;
}

%vertical-align-end {
  display: flex;
  align-items: flex-end;
}

%centered-flex {
  @extend %vertical-align-center;

  justify-content: center;
}

%vertical-flex {
  display: flex;
  flex-direction: column;
}

%embedded-centered {
  position: absolute;
  width: auto;
  max-width: 100%;
  max-height: 100%;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%amw-heading {
  color: $color__mine_shaft;
  font: 2.25rem/1em $font__primary--medium;
  margin: 0;
  text-transform: uppercase;
}

%base-layout-host {
  @extend %vertical-flex;
  flex-grow: 1;
  min-width: 0;
}

%base-layout {
  width: 100%;
  max-width: $base__max-width;
  margin: $base-margin;
  padding: $base__padding;
  position: relative;

  @include less_than_desktop_inclusive {
    max-width: 100vw;
    padding: $gap__md 30px;
  }

  @include less_than_tablet {
    padding: $gap__md;
  }
}

%disabled-cursor {
  cursor: not-allowed;
}

%disabled-field {
  background: $color__white;
  border-color: $color__mine_shaft_50 !important;
  color: $color__mine_shaft_50;
  @extend %disabled-cursor;
}

%clickable-light {
  &:hover,
  &:focus {
    outline: 0;
    cursor: pointer;
    background: $color__black-light;
  }
}

%form-label {
  text-transform: capitalize;
  color: $color__mine_shaft;
  font-family: $font__primary--medium;
  font-weight: bold;
}

%form-left-col-width {
  width: 39%;
  max-width: 300px;

  @include md {
    width: 100%;
    max-width: 100%;
  }
}

%link {
  cursor: pointer;
  color: $color__mine_shaft_70;
  text-decoration: underline;
}

%menu-label {
  text-transform: inherit;
  color: $color__mine_shaft;
  font-family: $font__primary;
  font-weight: bold;
  font-size: 16px;
}

%menu-trigger-control-mobile {
  @include dimension($header__height);

  display: block;
  padding: 16px;
  background: $color__mine_shaft;
  color: $color__white;
  font-size: 32px;

  &:before {
    margin: 0;
  }
}

%menu-close-control-mobile {
  $h-margin: -1px;
  $v-margin: calc(100% + 1px);

  @extend %menu-trigger-control-mobile;
  display: none;
  position: absolute;

  @include md {
    display: block;
  }

  .before & {
    right: $h-margin;
  }

  .after & {
    left: $h-margin;
  }

  .below & {
    bottom: $v-margin;
  }

  .above & {
    top: $v-margin;
  }
}

%amw-sidebar {
  display: flex;
  width: 38%;

  @include lg_s {
    width: 100%;
  }
}

%indentBetweenAdjacentButtons {
  amw-button + amw-button {
    margin-left: 32px;
  }
}

%amw-renewal-summary {
  background: $color__alabaster;
  padding: 24px;
  margin: 0;
  width: fit-content;

  @include less_than_tablet {
    padding: 16px;
  }

  .title {
    font: $font__size--large $font__primary--bold;
    line-height: 28px;
  }
}

$full-width-layout-vertical-indent: 60px;
$full-width-layout-left-indent: 0;
$full-width-layout-right-indent: 90px;

$full-width-layout-left-lg-l-indent: 10vw;

$full-width-layout-right-lg-indent: 40px;

$full-width-layout-right-md-l-indent: 7.4vw;

$full-width-layout-left-right-md-indent: 6.3vw;
$full-width-layout-top-bottom-md-indent: 0;

%full-width-layout-margins {
  margin: $full-width-layout-left-indent (-$full-width-layout-right-indent) (-$full-width-layout-vertical-indent) $full-width-layout-left-indent;

  @include lg_l {
    margin-left: -$full-width-layout-left-lg-l-indent;
  }

  @include lg {
    margin-right: -$full-width-layout-right-lg-indent;
  }

  @include lg_s {
    margin: $full-width-layout-top-bottom-md-indent (-$full-width-layout-right-md-l-indent);
  }

  @include md {
    margin: $full-width-layout-top-bottom-md-indent (-$full-width-layout-left-right-md-indent);
  }
}

%full-width-layout-paddings {
  padding: $full-width-layout-vertical-indent $full-width-layout-right-indent 40px $full-width-layout-left-indent;

  @include lg_l {
    padding-left: $full-width-layout-left-lg-l-indent;
  }

  @include lg {
    padding-right: $full-width-layout-right-lg-indent;
  }

  @include lg_s {
    padding: $full-width-layout-top-bottom-md-indent $full-width-layout-right-md-l-indent;
  }

  @include md {
    padding: $full-width-layout-top-bottom-md-indent $full-width-layout-left-right-md-indent;
  }
}

%component-with-loader {
  display: block;
  position: relative;
  min-height: 100px;
}

$margin-top: 6px;

%amw-product-card-points {
    margin-top: $margin-top;
    font: $font__size--medium $font__primary--medium;
    color: $color__purple;

    .amw-icon-heart {
      font-size: 11px;
    }

    .points-label {
      margin-left: 2px;
    }
}

%amw-product-card {
  .price-wrapper {
    margin-top: $margin-top;
    font: list.slash($font__size, 20px) $font__primary--bold;
    color: $color__mine_shaft;

    @include sm_l {
      font-size: $font__size--medium;
    }
  }

  .points {
    @extend %amw-product-card-points
  }
}

%text-overflow-multiline {
  overflow: hidden;
  text-overflow: ellipsis;
}

%text-overflow {
  @extend %text-overflow-multiline;

  white-space: nowrap;
}

%amw-custom-scroll {
  overflow-y: auto;
  margin-right: -20px;
  padding-right: 20px;

  &::-webkit-scrollbar-track {
    width: 4px;
    background-color: $color__silver2_50;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $color__nobel_50;
    width: 5px;
  }

  /*  for Mozilla: */
  scrollbar-color: $color__nobel_50 $color__silver2_50;
  scrollbar-width: thin;
}

// this is workaround to be able to avoid cutting outline that happened by "overflow hidden" style
%shift-for-outline {
  margin-left: -$outline-width;
  margin-right: -$outline-width;
  padding-left: $outline-width;
  padding-right: $outline-width;
}

%focus-outline {
  outline: $outline-width solid $color__pigeon-blue-light;
}

%reclassify-warnings-list {
  max-width: 576px;
  padding: 11px $gap__md;
  margin-bottom: $gap__lg;
  border-radius: 10px;
  background: $color__old_lace;
  color: $color__granite_gray;

  li {
    margin-bottom: $gap__sm;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//amw-wave-header
%padding-top-for-wave-header {
  padding-top: $amw-wave-header-height;

  @include lg {
    padding-top: $amw-wave-header-height__lg;
  }

  @include md_l {
    padding-top: $amw-wave-header-height__md_l;
  }

  @include sm {
    padding-top: $amw-wave-header-height__sm;
  }
}

%wave-header-user-info {
  .welcome-name {
    font: $default_font_size--welcome-name $font__primary--bold;

    @extend %text-overflow;

    @include md {
      @include setMultilineEllipsisText(2);

      font-size: $font__header-size--medium;
      white-space: normal;
      word-break: break-word;
    }

    @include sm_l {
      font-size: $font__size--large;
    }

    @include sm {
      font-size: $font__size--big;
    }
  }

  .ibo-num {
    font-size: $default_font_size--ibo-num;

    @include md {
      font-size: $font__size--big;
    }

    @include sm_l {
      font-size: $font__size--medium;
    }

    @include sm {
      font-size: $font__size--medium;
    }
  }

  .full-name {
    font: $font__size--large $font__primary--bold;
    max-width: 1000px;

    @include sm {
      font-size: $font__size--big;
    }
  }
}

%wave-header-paddings {
  --wave-header-max-width-container: #{ $base__max-width };
  --wave-header-margin-container: #{ $base-margin };
  --wave-header-padding-container: 0 40px;

  @include less_than_desktop_inclusive {
    --wave-header-max-width-container: 100vw;
    --wave-header-padding-container: 0 30px;
  }

  @include less_than_tablet {
    --wave-header-padding-container: 0 16px;
  }
}

%text-field {
  width: 100%;
  overflow: auto;
  max-width: 100%;
  white-space: pre-wrap;
  padding: $text-field-padding-vertical $text-field-padding-horizontal;
  font: $font__size--medium $font__primary--medium;
  line-height: $line__height--big;
  border: 2px solid $color__mine_shaft;
  color: $color__mine_shaft;
  border-radius: 10px;
}

%border-radius-fifty-percent {
  border-radius: $fifty-percent;
}
