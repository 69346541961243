@use "sass:math";

@use "sass:map";
@import './variables';

@mixin less_than_mobile {
  @media (max-width: $breakpoint__sm - 1) {
    @content;
  }
}

@mixin less_than_tablet {
  @media (max-width: $breakpoint__md - 1) {
    @content;
  }
}

@mixin less_than_desktop_inclusive {
  @media (max-width: $breakpoint__md_l - 1) {
    @content;
  }
}

@mixin from_tablet {
  @media (min-width: $breakpoint__md) {
    @content;
  }
}

@mixin from_tablet_to_desktop_inclusive {
  @media (min-width: $breakpoint__md) and (max-width: $breakpoint__md_l - 1) {
    @content;
  }
}

@mixin from_lg_to_breakpoint__md_l_inclusive {
  @media (min-width: $breakpoint__md_l) and (max-width: $breakpoint__lg) {
    @content;
  }
}

@mixin over_lg_exclusive {
  @media (min-width: ($breakpoint__lg_l + 1)) {
    @content;
  }
}

@mixin over_lg_l {
  @media (min-width: $breakpoint__lg_l) {
    @content;
  }
}

@mixin over_lg {
  @media (min-width: $breakpoint__lg) {
    @content;
  }
}

@mixin over_md_m {
  @media (min-width: $breakpoint__md_m) {
    @content;
  }
}

@mixin lg_l {
  @media (max-width: $breakpoint__lg_l) {
    @content;
  }
}

@mixin over__md_l {
  @media (min-width: $breakpoint__md_l) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $breakpoint__lg) {
    @content;
  }
}

@mixin lg_s {
  @media (max-width: $breakpoint__lg_s) {
    @content;
  }
}

@mixin md_l {
  @media (max-width: $breakpoint__md_l) {
    @content;
  }
}

@mixin md_m {
  @media (max-width: $breakpoint__md_m) {
    @content;
  }
}

@mixin md {
  @media (max-width: $breakpoint__md) {
    @content;
  }
}

@mixin md_landscape {
  @media screen and (max-width: $breakpoint__md) and (orientation: landscape) {
    @content;
  }
}

@mixin md_s {
  @media (max-width: $breakpoint__md_s) {
    @content;
  }
}

@mixin over_md_s {
  @media (min-width: $breakpoint__md_s) {
    @content;
  }
}

@mixin sm_l {
  @media (max-width: $breakpoint__sm_l) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $breakpoint__sm) {
    @content;
  }
}

@mixin arrow_framed_pointing_up($dimension: 14px, $boder-color: $color__grey,  $color: $color__white) {
  bottom: 100%;
  left: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  border-color: $boder-color;
  border-bottom-color: $color;
  border-width: $dimension;
  margin-left: -$dimension;
}

@mixin triangle($dimension: 14px, $color: $color__white, $direction: 'up') {
  $hSide: $dimension solid transparent;
  $vSide: $dimension solid $color;

  width: 0;
  height: 0;
  border-left: $hSide;
  border-right: $hSide;

  @if $direction == 'down' {
    border-top: $vSide; // down
  } @else {
    border-bottom: $vSide; // up
  }

}

@mixin dimension($size) {
  width: $size;
  height: $size;
}

@mixin round_borders($top-left, $top-right, $bottom-right, $bottom-left ) {
  @if $top-left {
    border-top-left-radius: $top-left;
  }

  @if $top-right {
    border-top-right-radius: $top-right;
  }

  @if $bottom-right {
    border-bottom-right-radius: $bottom-right;
  }

  @if $bottom-left {
    border-bottom-left-radius: $bottom-left;
  }
}

@mixin tooltip_side_options($triangle_color, $extra_indent: 0) {
  &-below {
    padding-top: 8px !important;

    .mat-tooltip:after {
      top: 0%;
      left: 50%;
      border-color: transparent transparent $triangle_color transparent;
      transform: translateY(-10px);
    }
  }

  &-right {
    padding-left: 8px !important;

    .mat-tooltip:after {
      top: 50%;
      left: 0%;
      border-color: transparent $triangle_color transparent transparent;
      transform: translateX(-5px) translateY(-5px);
    }
  }

  &-above {
    padding-bottom: 8px !important;

    .mat-tooltip:after {
      border-color: $triangle_color transparent transparent transparent;
      top: 100%;
      left: 50%;
    }
  }

  &-left {
    padding-right: 8px !important;

    .mat-tooltip:after {
      top: 50%;
      left: 100%;
      border-color: transparent transparent transparent $triangle_color;
      transform: translateX(5px) translateY(-5px);
    }
  }
}

@mixin setAbsoluteTopRightPosition($top, $right) {
  position: absolute;
  top: $top;
  right: $right;
}

@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin setMultilineEllipsisText($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin link-underline-style {
  font-family: $font__primary--bold;

  &:hover {
    color: $color__mine_shaft_70;
    border-bottom: 2px solid;
  }

  &:active {
    color: $color__mine_shaft_50;
  }
}

@mixin closeTopRightButton($dimension) {
  color: $color__white;
  background-color: $color__black;
  @include dimension($dimension);
}

@mixin reclassificationContentStyles($paddingDefault, $paddingMobile) {
  .amw-reclassification-modal {
    .amw-modal [mat-dialog-content] {
      padding: $paddingDefault;

      @include md {
        padding: $paddingMobile;
      }
    }
  }
}

@mixin innerShadowOutline($depth) {
  outline: 0;
  box-shadow: inset $depth $depth 0 $color__pigeon-blue-light,
  inset -#{$depth} -#{$depth} 0 $color__pigeon-blue-light,
  inset -#{$depth} #{$depth} 0 $color__pigeon-blue-light,
  inset #{$depth} -#{$depth} 0 $color__pigeon-blue-light;
}

@mixin wave-header-modal-default-styles {
  --header-padding: 0;
  --header-mobile-padding: 0;
  --wave-header-max-width-container: 100%;
  --wave-header-margin-container: 0 0 0 152px;
  --small-btn-margin: 0;

  @include md_m {
    --wave-header-margin-container: 0 0 0 calc(10% + 30px);
  }

  @include less_than_tablet {
    --wave-header-margin-container: 0 0 0 #{$gap__md};
  }

  &__header {
    &-close-btn {
      @include setAbsoluteTopRightPosition(3px, 6px);
    }

    &-user-info {
      .full-name {
        font: $font__size--large $font__primary--bold;
        max-width: 1000px;
      }

      .ibo-num {
        font-size: $font__size--big;
      }

      @include sm {
        font-size: $font__size--big;

        .full-name {
          font-size: $font__size--big;
        }

        .ibo-num {
          font-size: $font__size--medium;
        }
      }
    }
  }
}

@mixin common-modal-ditto-renewal-styles {
  &__content {
    max-width: 576px;
    margin-left: 112px;

    @include md_m {
      margin: 0 10%;
    }

    @include less_than_tablet {
      margin: unset;
    }
  }

  .title {
    font: $font__header-size $font__primary--bold;
    margin-bottom: 12px;

    @include less_than_tablet {
      font-size: $font__header-size--medium;
    }
  }

  .description {
    font: math.div($font__size, $line__height--large);
    margin-bottom: $gap__lg;
  }
}

@mixin layout-with-side-bar($padding-top-bottom: 60px, $margin-top: 18px) {
  .layout-with-side-bar {
    display: flex;
    flex-grow: 1;
    margin-top: $margin-top;

    @include lg_s {
      flex-direction: column;
    }

    &__content {
      flex-grow: 1;
      flex-basis: 0;
      position: relative;
      min-width: 0;
      padding: 0 90px $padding-top-bottom 0;
      width: 62%;

      @include lg {
        padding: 0 40px $padding-top-bottom 0;
      }

      @include lg_s {
        flex-grow: 0;
        padding: 25px 0;
        width: 100%;
      }
    }
  }
}

@mixin modal-width-by-breakpoint($modal-width-map) {
  width: var(--modal-width, map.get($modal-width-map, "lg_up"));
  max-width: var(--modal-max-width, map.get($modal-width-map, "max"));

  @include lg {
    width: var(--modal-width, map.get($modal-width-map, "lg_down"));
    max-width: var(--modal-max-width, map.get($modal-width-map, "lg_down"));
  }

  @include md_l {
    width: var(--modal-width, map.get($modal-width-map, "md_l_down"));
    max-width: var(--modal-max-width, map.get($modal-width-map, "md_l_down"));
  }

  @include md {
    width: var(--modal-width, map.get($modal-width-map, "md_down"));
    max-width: var(--modal-max-width, map.get($modal-width-map, "md_down"));
  }

  @include sm_l {
    width: var(--modal-width, map.get($modal-width-map, "sm_l_down"));
    max-width: var(--modal-max-width, map.get($modal-width-map, "sm_l_down"));
  }
}

@mixin modal-buttons-column-mobile {
  .actions-container {
    --button-width: 100%;
    flex-direction: column;
    margin: -6px 0;

    > amw-button {
      width: 100%;
      text-align: center;
      padding: 6px 0;

      > button.amw-button[role="link"] {
        --button-width: auto;
      }

      &.dark {
        order: 0;
      }

      &.light {
        order: 1;
      }

      &.link-btn {
        order: 2;
      }
    }
  }
}

@mixin modal-buttons-row {
  .actions-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin: 0 -16px;

    > amw-button {
      display: flex;
      padding: 0 16px;
      width: auto;

      &.dark {
        order: 1;
      }

      &.light {
        order: 0;
      }

      &.link-btn {
        order: 2;
      }
    }
  }
}
