@import './variables';

.cdk-overlay-container {
  z-index: $z-index-modal;
}

.mat-dialog-container {
  display: block;
  margin: 0 auto;
  color: inherit;
  font: inherit;
  box-shadow: none;

  .cdk-overlay-pane & { // for increased selector weight
    padding: 0;
    border-radius: 0;
  }

  .cdk-overlay-pane:not(.full-width):not(.full-screen-modal) & {
    border-radius: 20px;
  }
}
