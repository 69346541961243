@import 'scss/placeholders';
@import 'scss/variables';

$padding-left: 8px;

.mat-radio-button.mat-accent {
  position: relative;

  .mat-radio-inner-circle {
    color: $color__mine_shaft;
    background-color: $color__mine_shaft;
  }

  .mat-radio-outer-circle {
    border-color: $color__mine_shaft !important;
  }

  // to solve problem with scrollHeight property
  .mat-radio-input {
    bottom: unset;
    top: 0;
  }

  .mat-ripple {
    display: none;
  }

  .mat-radio-label {
    @extend %vertical-align-start;
  }

  .mat-radio-label-content {
    font: $font__size $font__primary;
    white-space: normal;
    padding-left: $padding-left;
  }

  &.cdk-keyboard-focused,
  &.sr-focusable.cdk-program-focused {
    .mat-radio-container {
      @extend %focus-outline;
    }
  }

  &:hover {
    .mat-radio-inner-circle {
      background-color: $color__mine_shaft_70;
    }

    .mat-radio-outer-circle {
      border-color: $color__mine_shaft_70 !important;
    }
  }

  &.mat-radio-disabled {
    .mat-radio-inner-circle {
      background-color: $color__mine_shaft_50;
    }

    .mat-radio-outer-circle {
      border-color: $color__mine_shaft_50 !important;
    }
  }
}

.error-inside-circle {
  .mat-radio-button.mat-accent {
    .mat-radio-inner-circle {
      color: $color__crimson-dark;
      background-color: $color__crimson-dark;
    }
  }
}

.error-outside-circle {
  .mat-radio-button.mat-accent:not(.mat-radio-checked) {
    .mat-radio-outer-circle {
      border-color: $color__crimson-dark !important;
    }
  }
}
